import { AgilityExportButtonComponent } from './agility-export-button.component';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { MenuModule } from 'primeng/menu';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [AgilityExportButtonComponent],
  imports: [ButtonModule, CommonModule, MenuModule],
  exports: [AgilityExportButtonComponent],
})
export class AgilityExportButtonModule {}
