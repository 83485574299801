export type WorkflowStatusCollateralType =
  (typeof WorkflowStatusCollateralStringTypes)[keyof typeof WorkflowStatusCollateralStringTypes];

export const WorkflowStatusCollateralStringTypes = {
  READY_TO_SHIP: 'readyToShip',
  PENDING_SHIP_REQUEST: 'pendingShipRequest',
  PENDING_INVESTOR: 'pendingInvestor',
  WET: 'wet',
  WET_GREATER_THAN_5: 'wet>5',
  IN_REVIEW: 'inReview',
  PENDING_SHIPPING_INSTRUCTIONS: 'pendingShippingInstructions',
  NOTES_OUTSTANDING_GREATER_THAN_30: 'notesOutstanding>30',
  COLLATERAL_RETURNED: 'collateralReturned',
  NOT_ACCEPTED: 'notAccepted',
} as const;
