import { Injectable } from '@angular/core';
import { Apollo, MutationResult, gql } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import { ShipCollateralWithIntegration } from '../../models/ShipCollateralWithIntegration.interface';
import { ABAAndAccNoInput } from '../../models/aba-and-accno-input.interface';
import { AdditionalWire } from '../../models/additionalWire.interface';
import { AdditionalWireStatus } from '../../models/additionalWireStatus.interface';
import { AdjustFee } from '../../models/adjust-fee.interface';
import { AdjustInterest } from '../../models/adjust-interest.interface';
import { CheckSettlement } from '../../models/check-settlement.interface';

import { CollectFee } from '../../models/collect-fee.interface';
import { CollectInterest } from '../../models/collect-interest.interface';
import { CreateTenantIndexInput } from '../../models/create-tenant-index-input.interface';
import { DeleteDocument } from '../../models/delete-document.interface';
import { LoanSearch } from '../../models/loan-search.interface';
import { ProductRequest } from '../../models/product.interface';
import { ReturnInput } from '../../models/return-input.interface';
import { SettlementCountInput } from '../../models/settlement-count-input.interface';
import { SettlementLoan } from '../../models/settlement-loan.interface';
import { shipCollateralWithOutIntegration } from '../../models/shipCollateralWithOutIntegration.interface';
import {
  IShippingDataResponse,
  IShippingOptionResponse,
  IShippingResponse,
} from '../../models/shipping.interface';
import { ShippingInstructions } from '../../models/shippingInstructions.interface';
import { SubTenantClosingAgentInput } from '../../models/subtenant-closing-agent-input.interface';
import { TenantInvestor } from '../../models/tenant-investor.interface';
import { UpdateTenantIndexInput } from '../../models/update-tenant-index-input.interface';
import { UpdateUserInput } from '../../models/update-user-input';
import { UpdateUserResponse } from '../../models/update-user-response';
import { GetUserStatusRequest } from '../../models/user-status-request.interface';
import { ValidateBatchLoanNumber } from '../../models/validate-batch-loan.interface';
import { ApolloQueryResult } from '@apollo/client';
import {
  Borrower,
  Loan,
  ShippingOption,
  Tenant,
} from 'src/app/graphql/generated';
import { RemoveNullandUndefined } from 'src/app/utils/types/utility.types';

// TODO: replace manual type with generated type
export type GetTenantById = {
  getTenantById: {
    tenantId: string;
    name: string;
    attn: string;
    lenderAddressStreet: string;
    lenderAddressCity: string;
    lenderAddressZip: string;
    lenderAddressState: string;
    isMessageActive: boolean;
    message: string;
    accountNumber: string;
    abaNumber: string;
    bankName: string;
    custodianNames: string[];
    selfCustodian: boolean;
    company: {
      companyName: string;
      primaryContactEmail: string;
      primaryContactPhoneNumber: string;
      primaryContactFirstName: string;
      primaryContactLastName: string;
    };
    document: {
      documentId: string;
      createdOn: string;
      createdBy: string;
      documentType: string;
      documentName: string;
      documentURL: string;
      updatedBy: string;
      updatedOn: string;
      size: string;
    };
  };
};

// TODO: replace manual type with generated type
export type GetAllSubTenants = {
  getAllSubTenants: {
    subTenantId: string;
    originatorName: string;
    legalName: string;
    primaryContact: {
      contactName: string;
      email: string;
      fax: string;
    };
    lineDetails: {
      commitmentAmount: number;
    };
  }[];
};

// TODO: replace manual type with generated type
export type GetAllActivityLog = {
  getAllActivityLog: {
    subTenantId: string;
    eventDescription: string;
    createdBy: string;
    createdOn: string;
    tenantId: string;
    originType: string;
    messageType: string;
    eventData: string;
  }[];
};

export type ShippingPipelineRecordsResponse = {
  getShippingPipelineRecords: ShippingPipelineResponseObj;
};

type LoansWithPrimaryBorrowerExtension = RemoveNullandUndefined<Loan> & {
  primaryBorrower?: Borrower;
};

export type ShippingPipelineResponseObj = {
  loans: LoansWithPrimaryBorrowerExtension[];
  tenant: RemoveNullandUndefined<Tenant>;
  tenantEasyPostOpt: RemoveNullandUndefined<ShippingOption>[];
};

@Injectable({
  providedIn: 'root',
})
export class GraphqlApisService {
  private GET_ENUMERATIONS = gql`
    query GetEnumerations {
      getEnumerations {
        amortizationTypes
        borrowerTypes
        appraisalTypes
        citizenShips
        documentationTypes
        lienPositions
        occupancies
        propertyTypes
        refinanceTypes
        transactionTypes
        productCodes
        legalTypes
        originatorStatus
        accrualMethods
        accountType
        indexUpdateFreq
        weekDays
        monthDays
        bankAccountType
        shippingVendor
        indexUpdateFreq
        weekDays
        monthDays
        carriers {
          carrierName
          parcelTypes
          serviceLevels
        }
        minUtilBasis
        states {
          stateCode
          stateName
        }
        indices {
          indexType
          indexSubTypes
        }
        productTypes
        investors
        masterDocuments {
          documentType
          documentSubTypes
        }
        conditionDecisions
        advanceBasis
        productStatus
        custodianNames
        closingAgentStatus
        closingAgentTypes
        closingAgentBranchStatus
        productNames
        feeType
        conditionSource
        feeType
        collateralStatus
        collectionDay
        dashboardItems
        collateralStatusUsers
        drawTypes
      }
    }
  `;
  private GET_LOAN_STATUS = gql`
    query GetLoanStatus($fortaLoanId: String!) {
      getLoanById(fortaLoanId: $fortaLoanId) {
        fortaLoanId
        status
      }
    }
  `;

  private GET_LOAN_BY_ID = gql`
    query GetLoanById($fortaLoanId: String, $displayLoanId: String) {
      getLoanById(fortaLoanId: $fortaLoanId, displayLoanId: $displayLoanId) {
        subTenantId
        tenantId
        fortaLoanId
        displayLoanId
        productName
        productId
        fedRefNumber
        status
        createdBy
        createdOn
        updatedBy
        updatedOn
        originatorParticipation
        maxWHPrincipal
        currentWHPrincipal
        borrowers {
          primary
          citizenship
          firstName
          lastName
          middleName
          dob
          ssn
          borrowerType
          totalAnnualIncome
          creditScore
          trustName
          businessName
          einNumber
          borrowerId
          fullSsn
          fullEinNumber
        }
        loanClosingAgent {
          primaryBankABA
          orderNumber
          closingAgentName
          closingAgentAddress
          closingAgentCity
          closingAgentState
          closingAgentZip
          address
          address2
          city
          state
          zip
          bankName
          accountNumber
          accountName
          bankNameFCT
          address
          address2
          city
          state
          zip
          closingAgentBranch
          agentId
          secureInsightEACode
          fctAbaAccountNumber
          closingAgentId
          locationId
          accountId
        }
        collateral {
          acquisitionSource
          address
          city
          cltv
          collateralStatus
          propertyType
          county
          ltv
          occupancy
          salePrice
          state
          unitCount
          wet
          zip
          note {
            amount
            date
            rate
            term
          }
          appraisal {
            appraisalCompany
            appraisedValue
            appraisedDate
            appraisalType
            appraisalName
          }
          trackingNumber
          trackingUrl
          updatedBy
          receivedBy
          updatedDate
          receivedDate
          acceptedDate
          comments
          propertyCount
        }
        investor {
          investorName
          takeoutPercent
          takeoutExpiration
          investorId
        }
        originator {
          originatorName
          loanNumber
          productCode
        }
        loanDetail {
          amortizationType
          cashOutAmount
          requestedWireDate
          documentationType
          dtiBackend
          dtiFrontend
          eNote
          firstDueDate
          lienPosition
          mersNum
          paymentAmount
          piti
          refinanceType
          transactionType
          loanAmount
          warehousePrinciple
          originatorParticipation
          otherLiens
          wireAmount
          highPriorityIndicator
          mip
          originalPrinBal
          unpaidPrinBal
          lastPaymentDate
          nextPaymentDate
          principalAndInterest
          purchasePrice
          maturityDate
          amortizationTerm
        }
        documents {
          createdBy
          createdOn
          documentId
          documentName
          documentType
          documentURL
          updatedBy
          updatedOn
          size
        }
        isSecureInsightEnable
        additionalProductDetails {
          builderName
          totalBudget
          propertyCount
          hcltv
        }
      }
    }
  `;
  private GET_LOAN_SUMMARY_BY_ID = gql`
    query GetLoanSummaryById($request: LoanSummaryRequest!) {
      getLoanSummaryById(request: $request) {
        productName
        subTenantId
        tenantId
        fortaLoanId
        displayLoanId
        status
        wet
        highPriorityIndicator
        advanceRatePercent
        originatorParticipation
        creditScore
        miniCreditScore
        loanFundingAgeDays
        warehousePrinciple
        operationalAccountBalance
        sublimitBalance
        productDetailsSublimit
        minDwellPeriod
        maxDwellPeriod
        dwellPeriodAdvanceRate
      }
    }
  `;
  private ADD_LOAN = gql`
    mutation AddLoan($loan: LoanInput!) {
      addLoan(loan: $loan) {
        subTenantId
        tenantId
        fortaLoanId
        status
        createdBy
        createdOn
        updatedBy
        updatedOn
      }
    }
  `;

  private CANCEL_LOAN = gql`
    mutation CancelLoan($fortaLoanId: String!, $subTenantId: String) {
      cancelLoan(fortaLoanId: $fortaLoanId, subTenantId: $subTenantId) {
        subTenantId
        tenantId
        displayLoanId
        fortaLoanId
        status
        createdBy
        createdOn
        updatedBy
        updatedOn
        cancelledOn
      }
    }
  `;
  private ADD_TENANT = gql`
    mutation AddTenant($tenant: TenantInput!) {
      addTenant(tenant: $tenant) {
        name
        url
        company {
          companyId
          companyName
          primaryContactEmail
          primaryContactFirstName
          primaryContactLastName
          primaryContactPhoneNumber
        }
      }
    }
  `;
  private GET_SHIPPINGS = gql`
    query GetShipping {
      getShipping {
        apiKey
        vendorName
        isActive
        shippingOptions {
          carrier
          parcelType
          accountId
          maxPages
          serviceLevel
          weight
          shippingOptionId
          shippingOptionName
        }
      }
    }
  `;

  private APPLY_CURTAILMENT_TRANSACTION = gql`
    mutation ApplyCurtailmentTransaction(
      $curtailmentTransactionRequest: CurtailmentTransactionRequest
    ) {
      applyCurtailmentTransaction(
        curtailmentTransactionRequest: $curtailmentTransactionRequest
      )
    }
  `;
  private ADD_SHIPPING = gql`
    mutation AddOrUpdateShipping($shippingInput: ShippingInput!) {
      addOrUpdateShipping(shippingInput: $shippingInput) {
        vendorName
        apiKey
        isActive
      }
    }
  `;
  private ADD_SHIPPING_OPTIONS = gql`
    mutation AddOrUpdateShippingOptions(
      $shippingOptionsInput: [ShippingOptionInput]
    ) {
      addOrUpdateShippingOptions(shippingOptionsInput: $shippingOptionsInput) {
        shippingOptionName
        carrier
        parcelType
        serviceLevel
        accountId
        weight
        maxPages
        vendorName
        shippingOptionId
      }
    }
  `;

  private ADD_FUNDING_CONDITIONS = gql`
    mutation AddOrUpdateManualLoanCondition(
      $manualCondition: ManualConditionInput!
    ) {
      addOrUpdateManualLoanCondition(manualCondition: $manualCondition) {
        conditionId
        conditionName
        softCondition
        createdBy
        createdDate
        conditionDecision
      }
    }
  `;

  private GET_TENANT_INDEX_VALUES_RESPONSE = gql`
    query GetTenantIndexesWithIndexName {
      getTenantIndexes {
        indexId
        indexType
        indexSubType
        indexRate
        apiFlag
        createdBy
        createdOn
        updatedBy
        updatedOn
        isActive
        indexName
      }
    }
  `;

  private UPDATE_TENANT_INDEX = gql`
    mutation UpdateTenantIndex(
      $updateTenantIndexInput: UpdateTenantIndexInput
    ) {
      updateTenantIndex(updateTenantIndexInput: $updateTenantIndexInput) {
        indexId
        indexType
        indexSubType
        apiFlag
        createdBy
        createdOn
        updatedBy
        updatedOn
        isActive
        indexName
      }
    }
  `;
  private UPDATE_TENANT_INDEX_RATE = gql`
    mutation UpdateIndexRate($updateIndexRateInput: UpdateIndexRateInput) {
      updateIndexRate(updateIndexRateInput: $updateIndexRateInput) {
        indexId
        indexType
        indexSubType
        indexRate
        apiFlag
        createdBy
        createdOn
        updatedBy
        updatedOn
        indexName
        isActive
      }
    }
  `;
  private GET_RECENTLY_SEARCHED_LOANS = gql`
    query GetLoginUserViewedLoanIds($size: Int) {
      getLoginUserViewedLoanIds(size: $size) {
        fortaLoanId
        displayLoanId
        status
        borrowers {
          primary
          firstName
          lastName
          middleName
          businessName
          trustName
          borrowerType
          businessName
        }
        collateral {
          wet
        }
        originator {
          originatorName
        }
        loanDetail {
          requestedWireDate
          loanAmount
          wireAmount
          highPriorityIndicator
        }
      }
    }
  `;
  private GET_LOAN_CONDITIONS = gql`
    query GetLoanFailedConditions($fortaLoanId: String!) {
      getLoanFailedConditions(fortaLoanId: $fortaLoanId) {
        conditionId
        conditionName
        conditionValue
        conditionRuleValue
        conditionDecision
        softCondition
        conditionTrigger
        status
        overridable
        externalViewable
        statusChangeLog
        createdBy
        createdDate
        decisionDate
        decisionMadeBy
        decisionExplanation
        document {
          documentURL
          documentName
          documentType
          documentId
          createdBy
          createdOn
          updatedBy
          updatedOn
          size
        }
      }
    }
  `;

  private GET_PRODUCT_SUMMARY = gql`
    query GetProductSummary($subTenantId: ID!) {
      subTenant(subTenantId: $subTenantId) {
        subTenantId
        product {
          productId
          productName
          productType
        }
        approvedClosingAgents {
          closingAgentName
          agentId
        }
      }
    }
  `;

  private PRODUCTS_BY_SUBTENENT = gql`
    query GetSubTenant($subTenantId: ID!) {
      subTenant(subTenantId: $subTenantId) {
        product {
          subTenantId
          productId
          productName
          productType
          subLimit
          subLimitBasis
          loanCount
          advanceBasis
          limit
          dwellPeriodsList {
            advanceRate
          }
          subLimitAccount {
            limit
            balance
          }
        }
      }
    }
  `;

  private GET_ALL_ACTIVITY_LOG = gql`
    query GetAllActivityLog($request: GetAllActivityLogInput!) {
      getAllActivityLog(request: $request) {
        subTenantId
        eventDescription
        createdBy
        createdOn
        tenantId
        originType
        messageType
        eventData
      }
    }
  `;

  private GET_SUB_TENANTS = gql`
    query GetAllSubTenants {
      subTenants {
        subTenantId
        originatorName
        legalName
        primaryContact {
          contactName
          email
          fax
        }
        lineDetails {
          commitmentAmount
        }
      }
    }
  `;

  private GET_ALL_SUB_TENANTS_SUMMARY = gql`
    query GetAllSubtenantsSummary {
      getAllSubtenantsSummary {
        subTenantId
        originatorName
        primaryContact {
          contactName
          email
          fax
        }
        lineDetails {
          commitmentAmount
        }
        loanIdPrefix
        currentWarehousePrincipal
      }
    }
  `;

  private GET_SUB_TENANT = gql`
    query GetSubTenantForLedger($subTenantId: ID!) {
      subTenant(subTenantId: $subTenantId) {
        subTenantId
        legalName
        originatorName
        dba
        legalType
        approvalStatus
        comment
        accManager
        agreementDate
        eOInsurance
        insurance
        aggregateLimit
        occurrenceLimit
        insuranceExp
        bondLimit
        loanIdPrefix
        primaryContact {
          contactName
          title
          email
          fax
        }
        mailingAddress {
          address1
          address2

          city
          state
          zip
        }
        accountSetups {
          accountType
          bankName
          bankAccountType
          bankBalance
          minResBalance
          aba
          accountNumber
          accountId
        }
        lineDetails {
          commitmentAmount
          tempCommitmentAmount
          committedAmt
          uncommittedAmt
          commitmentApprove
          amendmentDate
          wetLimit
          tempWetLimit
          wetApprove
          wetDwellDays
          eNoteDwellDays
          qcFileReview
          autoFundAddWires
          indexUpdateFrequency
          indexUpdateFreqValue
          indexAdjuster
          wetCurtailment
          startDate
          endDate
          firstDaysOfMon
          lastDaysOfMon
          contractEffectiveDate
          contractExpirationDate
          createdBy
          createdDate
          updatedBy
          updatedDate
          document {
            documentURL
            documentName
            documentType
            documentId
            createdBy
            createdOn
            updatedBy
            updatedOn
            size
          }
          eNoteDwellDays
          qcFileReview
        }
        stateLicenses {
          stateLicenseId
          stateName
          licenseExpirationDate
          licenseNumber
          document {
            documentURL
            documentName
            documentType
            documentId
            size
          }
        }
        product {
          productId
          productName
          productType
          mraDescription
          subLimit
          subLimitBasis
          limit
          advanceBasis
          indexBasis
          indexName
          subBasis
          eNote
          autoFund
          monthlyInterestCollection
          status
          productFloorRate
          indexUpdateFrequency
          indexUpdateFreqValue
          adjuster
          interestAccrualMethod
          requiredDocs {
            file
          }
          additionalDraws {
            file
          }
          additionalDrawsFlag
          productFees {
            dailyWetFee
            wireFee
            fileFee
            eNoteFee
            shippingFee
            drawFee
          }
          investorsList
          dwellPeriodsList {
            minDwellPeriod
            maxDwellPeriod
            marginInc
            agingFee
            advanceRate
            commentReq
          }
          rules {
            maxLoanAmount
            minFrontEndDti
            loanTermLimit
            maxLtv
            minCreditScore
            minLoanAmount
            maxCLTV
            noteDate
            ssnRequired
            occupancy
            states
            docTypes
            amortization
            maxLoanAmountActive
            minFrontEndDtiActive
            loanTermLimitActive
            maxLtvActive
            minCreditScoreActive
            minLoanAmountActive
            maxCLTVActive
            noteDateActive
            ssnRequiredActive
            occupancyActive
            statesActive
            docTypesActive
            amortizationActive
            maxFrontEndDti
            maxBackEndDti
            minimumNoteAmount
            maximumNoteAmount
            lienPosition
            propertyType
            maxCreditScore
            maxFrontEndDtiActive
            maxBackEndDtiActive
            minimumNoteAmountActive
            maximumNoteAmountActive
            lienPositionActive
            propertyTypeActive
            maxCreditScoreActive
          }
        }
        subTenantFees {
          dailyWetFee
          wireFee
          fileFee
          eNoteFee
          shippingFee
          drawFee
          minUtilization
          nonUtilization
          wetMargin
          accrualMethod
          floorRate
          isMinUtilizationActive
          minUtilizationBillingBasis
          minUtilizationStartDate
          minUtilizationCollectionDay
          isFacilityFeeActive
          facilityFee
          facilityFeeBillingBasis
          facilityFeeStartDate
          facilityFeeEndDate
          facilityFeeCollectionDay
          isCommitmentFeeActive
          commitmentFee
          commitmentFeeBillingBasis
          commitmentFeeStartDate
          commitmentFeeEndDate
          commitmentFeeCollectionDay
          isMiscFeeActive
          miscFee
          miscFeeType
          miscFeeBillingBasis
          miscFeeStartDate
          miscFeeEndDate
          miscFeeCollectionDay
        }
        accountSetups {
          accountType
          bankName
          bankAccountType
          bankBalance
          minResBalance
          aba
          accountNumber
          accountId
        }
      }
    }
  `;

  private GET_PRIMARY_CONTACT = gql`
    query GetSubTenantContactName($subTenantId: ID!) {
      subTenant(subTenantId: $subTenantId) {
        primaryContact {
          contactName
        }
      }
    }
  `;

  private GET_SUB_TENANT_NAME = gql`
    query GetSubTenantOriginatorName($subTenantId: ID!) {
      subTenant(subTenantId: $subTenantId) {
        originatorName
      }
    }
  `;

  private DELETE_SHIPPING = gql`
    mutation DeleteShippingOption(
      $shippingOptionId: String!
      $vendorName: String!
    ) {
      deleteShippingOption(
        shippingOptionId: $shippingOptionId
        vendorName: $vendorName
      ) {
        vendorName
      }
    }
  `;
  private UPDATE_LOAN_CONDITION = gql`
    mutation UpdateLoanCondition(
      $fortaLoanId: String!
      $conditionId: String!
      $decision: String!
      $explanation: String!
      $conditionTrigger: String!
    ) {
      updateLoanCondition(
        fortaLoanId: $fortaLoanId
        conditionId: $conditionId
        decision: $decision
        explanation: $explanation
        conditionTrigger: $conditionTrigger
      ) {
        decisionMadeBy
        decisionDate
        decisionExplanation
        conditionDecision
        status
        statusChangeLog
        conditionId
        conditionName
        conditionValue
        softCondition
        conditionTrigger
        overridable
        externalViewable
        createdBy
        createdDate
      }
    }
  `;

  private Get_SHIPPING_INSTRUCTIONS = gql`
    query GetShippingInstructionByFortaLoanId($fortaLoanId: String!) {
      getShippingInstructionByFortaLoanId(fortaLoanId: $fortaLoanId) {
        fortaLoanId
        investorId
        investorName
        investorAddress
        investorCity
        investorState
        investorZip
        investorPhoneNumber
        custodianName
        custodianAddress
        custodianCity
        custodianState
        custodianZip
        shippingRequestDate
      }
    }
  `;

  private UPDATE_SHIPPING_INSTRUCTIONS = gql`
    mutation UpdateShippingInstruction(
      $ShipingInstruction: ShippingInstructionInput!
    ) {
      updateShippingInstruction(ShipingInstruction: $ShipingInstruction) {
        fortaLoanId
        investorId
        investorName
        investorAddress
        investorCity
        investorState
        investorZip
        custodianName
        custodianAddress
        custodianCity
        custodianState
        custodianZip
        shippingRequestDate
      }
    }
  `;

  private CREATE_SHIPPING_INSTRUCTIONS = gql`
    mutation CreateShippingInstruction(
      $ShipingInstruction: ShippingInstructionInput!
    ) {
      createShippingInstruction(ShipingInstruction: $ShipingInstruction) {
        fortaLoanId
        investorId
        investorName
        investorAddress
        investorCity
        investorState
        investorZip
        investorPhoneNumber
        custodianName
        custodianAddress
        custodianCity
        custodianState
        custodianZip
        shippingRequestDate
      }
    }
  `;

  private DELETE_SHIPPING_INSTRUCTIONS = gql`
    mutation DeleteShippingInstruction($fortaLoanId: String!) {
      deleteShippingInstruction(fortaLoanId: $fortaLoanId)
    }
  `;

  private CREATE_TENANT_INDEX = gql`
    mutation CreateTenantIndex(
      $tenantIndexRequestInput: CreateTenantIndexInput!
    ) {
      createTenantIndex(tenantIndexRequestInput: $tenantIndexRequestInput) {
        indexName
        indexType
        indexSubType
        createdBy
        createdOn
        updatedBy
        updatedOn
      }
    }
  `;
  private SAVE_ADDITIONAL_WIRE = gql`
    mutation AddOrUpdateAdditionalWire(
      $additionalWireRequest: AdditionalWireRequest!
    ) {
      addOrUpdateAdditionalWire(additionalWireRequest: $additionalWireRequest) {
        additionalWireId
        updatedBy
        createdBy
        createdOn
        updatedOn
        wireAmount
        wireStatus
        requestedWireDate
        fedRefNumber
        orderNumber
        loanClosingAgent {
          primaryBankABA
          orderNumber
          closingAgentName
          closingAgentAddress
          closingAgentCity
          closingAgentState
          closingAgentZip
          bankName
          accountNumber
          accountName
          bankNameFCT
          closingAgentBranch
          agentId
          secureInsightEACode
          fctAbaAccountNumber
        }
      }
    }
  `;

  private UPDATE_ADDITIONAL_WIRE_FED_REF_NUMBER = gql`
    mutation UpdateWiresFedReferenceNumber(
      $updateWiresFedReferenceNumberInput: UpdateWiresFedReferenceNumberInput!
    ) {
      updateWiresFedReferenceNumber(
        updateWiresFedReferenceNumberInput: $updateWiresFedReferenceNumberInput
      ) {
        wireId
        fedRefNumber
        wireType
        wireAmount
        requestedWireDate
        wireStatus
        createdBy
        createdOn
        updatedBy
        updatedOn
      }
    }
  `;

  private CHANGE_ADDITIONAL_WIRE_STATUS = gql`
    mutation ChangeAdditionalWiresStatus(
      $additionalWiresStatusRequest: AdditionalWiresStatusRequest!
    ) {
      changeAdditionalWiresStatus(
        additionalWiresStatusRequest: $additionalWiresStatusRequest
      ) {
        additionalWireId
        updatedBy
        createdBy
        createdOn
        updatedOn
        wireAmount
        wireStatus
        requestedWireDate
        fedRefNumber
        loanClosingAgent {
          primaryBankABA
          orderNumber
          closingAgentName
          closingAgentAddress
          closingAgentCity
          closingAgentState
          closingAgentZip
          bankName
          accountNumber
          accountName
          bankNameFCT
          closingAgentBranch
          agentId
          secureInsightEACode
          fctAbaAccountNumber
        }
      }
    }
  `;

  private GET_ALL_SUBTENANT_CLOSING_AGENTS = gql`
    query GetAllSubTenantClosingAgents($subTenantId: String!) {
      getAllSubTenantClosingAgents(subTenantId: $subTenantId) {
        subTenantId
        tenantId
        subTenantClosingAgents {
          closingAgentId
          approved
          cplRequired
          updatedDate
          updatedBy
        }
      }
    }
  `;

  private SAVE_SUBTENANT_CLOSING_AGENTS = gql`
    mutation SaveSubTenantClosingAgents(
      $subTenantClosingAgentInput: SubTenantClosingAgentRequest
    ) {
      saveSubTenantClosingAgents(
        subTenantClosingAgentInput: $subTenantClosingAgentInput
      ) {
        subTenantId
        tenantId
        subTenantClosingAgents {
          closingAgentId
          cplRequired
          approved
          updatedDate
          updatedBy
        }
      }
    }
  `;

  private UPDATE_COLLATERAL = gql`
    mutation UpdateCollateral(
      $fortaLoanId: String!
      $collateral: CollateralInput
    ) {
      updateCollateral(fortaLoanId: $fortaLoanId, collateral: $collateral) {
        fortaLoanId
        collateral {
          collateralStatus
          updatedBy
          updatedDate
          comments
          trackingNumber
          trackingUrl
        }
      }
    }
  `;

  private GET_ALL_TENANT_INVESTOR = gql`
    query GetAllTenantInvestor {
      getAllTenantInvestor {
        investorId
        name
        shortName
        approved
        approvedBy
        approvedDate
        active
        phoneNumber
        ext
        contactName
        contactTitle
        emailAddress
        exposureLimit
        investorAddress {
          address
          address2
          city
          state
          zip
        }
        reverseBailee
        purchaseRON
        selfCustodian
        investorCustodians {
          custodianName
          custodianAddress
          custodianAddress2
          custodianCity
          custodianState
          custodianZip
          masterAddress
        }
        attention
        createdBy
        createdOn
        updatedBy
        updatedOn
      }
    }
  `;

  private GET_TENANT_INVESTOR = gql`
    query GetTenantInvestor($investorId: String!) {
      getTenantInvestor(investorId: $investorId) {
        investorId
        name
        shortName
        approved
        approvedBy
        approvedDate
        active
        phoneNumber
        ext
        contactName
        contactTitle
        emailAddress
        exposureLimit
        investorAddress {
          address
          address2
          city
          state
          zip
        }
        reverseBailee
        purchaseRON
        selfCustodian
        investorCustodians {
          custodianName
          custodianAddress
          custodianAddress2
          custodianCity
          custodianState
          custodianZip
          masterAddress
          company
          attention
          phone
        }
        attention
        createdBy
        createdOn
        updatedBy
        updatedOn
      }
    }
  `;

  private UPDATE_TENANT_INVESTOR = gql`
    mutation UpdateTenantInvestor(
      $updateTenantInvestorInput: UpdateTenantInvestorInput
    ) {
      updateTenantInvestor(
        updateTenantInvestorInput: $updateTenantInvestorInput
      ) {
        investorId
        name
        shortName
        approved
        approvedBy
        approvedDate
        active
        phoneNumber
        ext
        contactName
        contactTitle
        emailAddress
        exposureLimit
        investorAddress {
          address
          address2
          city
          state
          zip
        }
        reverseBailee
        purchaseRON
        selfCustodian
        investorCustodians {
          custodianName
          custodianAddress
          custodianAddress2
          custodianCity
          custodianState
          custodianZip
          masterAddress
          company
          attention
          phone
        }
        attention
        createdBy
        createdOn
        updatedBy
        updatedOn
      }
    }
  `;

  private ADD_TENANT_INVESTOR = gql`
    mutation AddTenantInvestor($tenantInvestorInput: TenantInvestorInput) {
      addTenantInvestor(tenantInvestorInput: $tenantInvestorInput) {
        investorId
        name
        shortName
        approved
        approvedBy
        approvedDate
        active
        phoneNumber
        ext
        contactName
        contactTitle
        emailAddress
        exposureLimit
        investorAddress {
          address
          address2
          city
          state
          zip
        }
        reverseBailee
        purchaseRON
        selfCustodian
        investorCustodians {
          custodianName
          custodianAddress
          custodianAddress2
          custodianCity
          custodianState
          custodianZip
          masterAddress
          company
          attention
          phone
        }
        attention
        createdBy
        createdOn
        updatedBy
        updatedOn
      }
    }
  `;

  private GET_STATE_LICENSES = gql`
    query GetStateLicenses($subTenantId: ID!) {
      subTenant(subTenantId: $subTenantId) {
        stateLicenses {
          stateName
          licenseNumber
          licenseExpirationDate
          document {
            documentId
            documentName
            documentType
            documentURL
            size
          }
        }
      }
    }
  `;

  private GET_SHIPPING_PIPELINE_RECORDS = gql`
    query GetShippingPipelineRecords {
      getShippingPipelineRecords {
        loans {
          borrowers {
            primary
            lastName
            firstName
          }
          displayLoanId
          fortaLoanId
          subTenantId
          originator {
            loanNumber
            originatorName
          }
          loanDetail {
            eNote
          }
          collateral {
            note {
              amount
            }
            collateralStatus
            wet
          }
          shippingInstruction {
            investorName
            custodianName
            custodianAddress
            custodianCity
            custodianState
            custodianZip
            shippingRequestDate
            investorPhoneNumber
          }
        }
        tenant {
          selfCustodian
        }
        tenantEasyPostOpt {
          carrier
          serviceLevel
          parcelType
          weight
          shippingOptionId
          shippingOptionName
        }
      }
    }
  `;

  private GET_ALL_SUB_TENANT_INVESTORS = gql`
    query GetAllSubTenantInvestors($subTenantId: String!) {
      getAllSubtenantInvestors(subTenantId: $subTenantId) {
        investorId
        name
        isApproved
        exposureLimit
        imbLimit
        email
        document {
          documentId
          documentName
          documentType
          documentURL
          size
          updatedOn
          updatedBy
          createdOn
          createdBy
        }
      }
    }
  `;

  private MANUAL_VENDOR_VALIDATION = gql`
    query ManualVendorValidation($fortaLoanId: String!) {
      manualVendorValidation(fortaLoanId: $fortaLoanId) {
        success
      }
    }
  `;

  private GET_APPROVED_AND_ACTIVE_INVESTORS = gql`
    query GetApprovedAndActiveInvestors {
      getApprovedAndActiveInvestors {
        investorId
        name
        emailAddress
        exposureLimit
      }
    }
  `;
  private SHIP_COLLATERAL_WITH_INTEGRATION = gql`
    mutation ShipCollateralWithIntegration(
      $shipCollateralWithIntegrationRequest: ShipCollateralWithIntegrationRequest
    ) {
      createShippingTransactionWithIntegration(
        shipCollateralWithIntegrationRequest: $shipCollateralWithIntegrationRequest
      ) {
        shippingTransactionId
        loanNumbers
        shippingTransactionId
        documents {
          documentURL
          documentName
          documentType
        }
      }
    }
  `;

  private SHIP_COLLATERAL_WITHOUT_INTEGRATION = gql`
    mutation ShipCollateralWithoutIntegration(
      $selfCustodianFlag: Boolean!
      $shipCollateralWithOutIntegrationRequest: ShipCollateralWithOutIntegrationRequest
    ) {
      createShippingTransactionWithOutIntegration(
        selfCustodianFlag: $selfCustodianFlag
        shipCollateralWithOutIntegrationRequest: $shipCollateralWithOutIntegrationRequest
      ) {
        shippingTransactionId
        loanNumbers
        shippingTransactionId
        documents {
          documentURL
          documentName
          documentType
        }
      }
    }
  `;

  private UPDATE_FED_REF_NUMBER = gql`
    mutation UpdateFedReferenceNumber(
      $fortaLoanId: String!
      $fedRefNumber: String!
    ) {
      updateFedReferenceNumber(
        fortaLoanId: $fortaLoanId
        fedRefNumber: $fedRefNumber
      ) {
        subTenantId
        tenantId
        fortaLoanId
        status
      }
    }
  `;

  private VALIDATE_BACTH_LOAN_NUMBERS = gql`
    query ValidateBatchLoanNumbers($validateLoanInput: ValidateLoanInput) {
      validateBatchLoanNumbers(validateLoanInput: $validateLoanInput) {
        loanNumber
        isValid
        isProcessed
      }
    }
  `;

  private GET_SHIPPED_LOANS = gql`
    query SearchLoanForSettlement($addLoanRequest: AddLoanRequest) {
      searchLoanForSettlement(addLoanRequest: $addLoanRequest) {
        subTenantId
        fortaLoanId
        displayLoanId
        status
        originator {
          originatorName
          loanNumber
        }
        borrowers {
          primary
          borrowerType
          firstName
          lastName
          citizenship
          businessName
          trustName
          businessName
        }
        collateral {
          address
          city
          state
          zip
          propertyType
          occupancy
          note {
            amount
          }
        }
        loanDetail {
          warehousePrinciple
        }
        feeAmount
        interestAmount
        totalOutstanding
      }
    }
  `;

  private GET_ALL_LOANS_BY_STATUS = gql`
    query GetAllLoansByStatus(
      $getAllLoansByStatusRequest: GetAllLoansByStatusRequest
    ) {
      getAllLoansByStatus(
        getAllLoansByStatusRequest: $getAllLoansByStatusRequest
      ) {
        status
        loans {
          fortaLoanId
          autoFund
          displayLoanId
          loanDetail {
            wireAmount
            loanAmount
            warehousePrinciple
            highPriorityIndicator
            requestedWireDate
          }
          borrowers {
            primary
            borrowerType
            firstName
            lastName
            businessName
            trustName
          }
          originator {
            originatorName
            loanNumber
          }
          collateral {
            address
            city
            state
            zip
            wet
          }
          status
          isNewlyCreated
          softConditionsCount
          hardConditionsCount
          fundedDate
        }
      }
    }
  `;

  private GET_MATCHING_WIRES_WITH_PURCHASE_ADVICE_AMOUNT = gql`
    query GetMatchingWires($purchaseAdviceAmount: Float!) {
      getMatchingWires(purchaseAdviceAmount: $purchaseAdviceAmount) {
        wireId
        referenceNumber
        receivedDate
        wireAmount
        comment
        investorName
        investorId
        originatorName
        subTenantId
        updatedBy
        updatedDate
      }
    }
  `;

  private GET_ALL_SETTLEMENT_WIRE = gql`
    query GetAllWires {
      getAllWires {
        settlementRequestId
        settlementMethod
        status
        comment
        investorName
        investorId
        originatorName
        wireAmount
        receivedDate
        referenceNumber
        subTenantId
        wireId
        loanInfos {
          fortaLoanId
          investorProceeds
        }
        documents {
          createdBy
          createdOn
          documentId
          documentName
          documentType
          documentURL
          updatedBy
          updatedOn
          size
        }
        createdDate
        createdBy
        updatedDate
        updatedBy
        sourceForm
        sumOfInvestorProceeds
        purchaseAdviceAmount
        returnFromCA
      }
    }
  `;

  private GET_LOAN_LEVEL_INTERESTS = gql`
    query GetLoanLevelInterests($fortaLoanId: String!) {
      getLoanLevelInterests(fortaLoanId: $fortaLoanId) {
        fortaLoanId
        transaction
        accrualMethod
        fortaLoanId
        indexSubType
        floorRate
        indexType
        date
        productMargin
        productFloor
        indexRate
        advanceAmount
        amount
        tenantId
        interestRate
        indexAdjuster
        wetMargin
        loanAgingDays
        noteRate
      }
    }
  `;

  private CHECK_DUPLICATE_WIRE_INSTRUCTION = gql`
    query CheckDuplicateWireInstruction($abaAndAccNoInput: ABAAndAccNoInput) {
      checkDuplicateWireInstruction(abaAndAccNoInput: $abaAndAccNoInput) {
        name
        status
        closingAgentId
      }
    }
  `;

  private COLLECT_INTEREST = gql`
    mutation CollectLoanInterestDetails(
      $collectLoanInterestInput: CollectLoanInterestInput
    ) {
      collectLoanInterestDetails(
        collectLoanInterestInput: $collectLoanInterestInput
      ) {
        fortaLoanId
        transaction
        date
        amount
        comment {
          commentId
          commentText
          commentedBy
          commentedDate
        }
      }
    }
  `;

  private ADJUST_INTEREST = gql`
    mutation AdjustLoanInterestDetails(
      $adjustLoanInterestInput: AdjustLoanInterestInput
    ) {
      adjustLoanInterestDetails(
        adjustLoanInterestInput: $adjustLoanInterestInput
      ) {
        fortaLoanId
        transaction
        date
        amount
        comment {
          commentId
          commentText
          commentedBy
          commentedDate
        }
      }
    }
  `;

  private GET_WIRE = gql`
    query GetWire($referenceNumber: String!) {
      getWire(referenceNumber: $referenceNumber) {
        receivedDate
        referenceNumber
        investorName
        originatorName
        wireAmount
        comment
        status
        wireId
        documents {
          documentId
          documentURL
          documentType
          documentName
        }
      }
    }
  `;

  private CONFIRM_WIRE = gql`
    mutation ConfirmWire($settlementWireId: String!) {
      confirmWire(settlementWireId: $settlementWireId) {
        wireId
        referenceNumber
        receivedDate
        wireAmount
        comment
        investorName
        investorId
        originatorName
        subTenantId
        status
        loanInfos {
          fortaLoanId
          investorProceeds
        }
        createdBy
        createdDate
        updatedBy
        updatedDate
      }
    }
  `;

  private GET_ALL_MANUAL_CONDITION_LIST = gql`
    query GetAllManualConditions {
      getAllManualConditions {
        conditionDescription
        conditionName
        softCondition
        source
        conditionType
        isActive
        conditionId
      }
    }
  `;

  private ADD_CONDITION = gql`
    mutation AddOrUpdateNewManualCondition($condition: Condition) {
      addOrUpdateNewManualCondition(condition: $condition) {
        conditionName
        conditionDescription
        source
        conditionType
        conditionId
        isActive
        softCondition
      }
    }
  `;

  private BULK_LOAN_REQUEST_CANCEL = gql`
    mutation BulkLoanRequestCancel(
      $bulkLoanRequestCancelInputs: [BulkLoanRequestCancelInput]!
    ) {
      bulkLoanRequestCancel(
        bulkLoanRequestCancelInputs: $bulkLoanRequestCancelInputs
      ) {
        errors
      }
    }
  `;

  private GET_ALL_SUBTENANT_APPROVED_TENANT_INVESTOR = gql`
    query GetAllSubTenantApprovedInvestors(
      $subTenantId: String!
      $productName: String
    ) {
      getAllSubTenantApprovedInvestors(
        subTenantId: $subTenantId
        productName: $productName
      ) {
        investorId
        name
        shortName
        approved
        approvedBy
        approvedDate
        active
        phoneNumber
        ext
        contactName
        contactTitle
        emailAddress
        exposureLimit
        investorAddress {
          address
          address2
          city
          state
          zip
        }
        reverseBailee
        purchaseRON
        selfCustodian
        investorCustodians {
          custodianName
          custodianAddress
          custodianAddress2
          custodianCity
          custodianState
          custodianZip
          masterAddress
          company
          attention
          phone
        }
        attention
        createdBy
        createdOn
        updatedBy
        updatedOn
      }
    }
  `;

  private UPDATE_COLLATERAL_STATUS = gql`
    mutation UpdateCollateralStatus(
      $BatchCollateralInput: [BatchCollateralInput]
    ) {
      updateCollateralStatus(BatchCollateralInput: $BatchCollateralInput) {
        fortaLoanId
        collateral {
          collateralStatus
          receivingTrackingNumber
        }
      }
    }
  `;

  private GET_COLLATERAL_DASHBOARD_WET_LOAN_BY_DAYS = gql`
    query GetCollateralDashboardWetLoanCountByDays(
      $subTenantIds: [String]!
      $days: Int
    ) {
      getCollateralDashboardWetLoanCountByDays(
        getCollateralDashboardWetLoanCount: {
          subTenantIds: $subTenantIds
          days: $days
        }
      ) {
        count
      }
    }
  `;

  private GET_WIRE_LOANS = gql`
    query GetWireLoans($wireId: String!) {
      getWireLoans(wireId: $wireId) {
        subTenantId
        tenantId
        fortaLoanId
        displayLoanId
        productName
        fedRefNumber
        investorProceeds
        status
        createdBy
        createdOn
        updatedBy
        updatedOn
        feeAmount
        interestAmount
        totalOutstanding
        borrowers {
          primary
          citizenship
          firstName
          lastName
          middleName
          dob
          ssn
          borrowerType
          totalAnnualIncome
          creditScore
          trustName
          businessName
          einNumber
        }
        collateral {
          address
          city
          state
          zip
          propertyType
          occupancy
          note {
            amount
          }
        }
        loanClosingAgent {
          primaryBankABA
          fctAbaAccountNumber
          orderNumber
          closingAgentName
          closingAgentAddress
          closingAgentCity
          closingAgentState
          closingAgentZip
        }
        investor {
          investorName
          takeoutPercent
          takeoutExpiration
        }
        originator {
          originatorName
          loanNumber
          productCode
        }
        loanDetail {
          amortizationType
          cashOutAmount
          requestedWireDate
          documentationType
          dtiBackend
          dtiFrontend
          eNote
          firstDueDate
          lienPosition
          mersNum
          paymentAmount
          piti
          refinanceType
          transactionType
          loanAmount
          warehousePrinciple
          otherLiens
          wireAmount
          highPriorityIndicator
          mip
          originalPrinBal
          unpaidPrinBal
          lastPaymentDate
          nextPaymentDate
        }
      }
    }
  `;

  private GET_LOAN_LEVEL_FEES = gql`
    query GetLoanLevelFees($fortaLoanId: String!) {
      getLoanLevelFees(fortaLoanId: $fortaLoanId) {
        fortaLoanId
        date
        feeType
        amount
        transaction
      }
    }
  `;

  private ADJUST_FEE = gql`
    mutation AdjustLoanFeeDetails($adjustLoanFeeInput: AdjustLoanFeeInput) {
      adjustLoanFeeDetails(adjustLoanFeeInput: $adjustLoanFeeInput) {
        date
        amount
        fortaLoanId
        transaction
        feeType
        comment {
          commentId
          commentText
          commentedBy
          commentedDate
        }
      }
    }
  `;

  private COLLECT_FEE = gql`
    mutation CollectLoanFeeDetails($collectLoanFeeInput: CollectLoanFeeInput) {
      collectLoanFeeDetails(collectLoanFeeInput: $collectLoanFeeInput) {
        loanLevelFees {
          date
          amount
          fortaLoanId
          transaction
          feeType
        }
        comment {
          commentId
          commentText
          commentedBy
          commentedDate
        }
      }
    }
  `;

  private GET_TENANT_BY_ID = gql`
    query GetTenantById {
      getTenantById {
        tenantId
        name
        attn
        lenderAddressStreet
        lenderAddressCity
        lenderAddressZip
        lenderAddressState
        isMessageActive
        message
        accountNumber
        accountName
        abaNumber
        bankName
        custodianNames
        selfCustodian
        company {
          companyName
          primaryContactEmail
          primaryContactPhoneNumber
          primaryContactFirstName
          primaryContactLastName
        }
        document {
          documentId
          createdOn
          createdBy
          documentType
          documentName
          documentURL
          updatedBy
          updatedOn
          size
        }
      }
    }
  `;
  private GET_ALL_TENANTS = gql`
    query GetAllTenants {
      getAllTenants {
        tenantId
        name
        attn
        lenderAddressStreet
        lenderAddressCity
        lenderAddressState
        lenderAddressZip
        company {
          companyName
          primaryContactEmail
          primaryContactPhoneNumber
        }
      }
    }
  `;

  private GET_TENANT_BY_TENANT_ID = gql`
    query GetTenantByTenantId($tenantId: String!) {
      getTenantByTenantId(tenantId: $tenantId) {
        tenantId
        name
        attn
        lenderAddressStreet
        lenderAddressCity
        lenderAddressZip
        lenderAddressState
        isMessageActive
        message
        accountNumber
        accountName
        abaNumber
        bankName
        custodianNames
        selfCustodian
        company {
          companyName
          primaryContactEmail
          primaryContactPhoneNumber
          primaryContactFirstName
          primaryContactLastName
        }
        document {
          documentId
          createdOn
          createdBy
          documentType
          documentName
          documentURL
          updatedBy
          updatedOn
          size
        }
      }
    }
  `;

  private CHECK_SETTLEMENT = gql`
    query CheckSettlement($checkSettlementInput: CheckSettlementInput) {
      checkSettlement(checkSettlementInput: $checkSettlementInput) {
        wireId
        referenceNumber
        receivedDate
        wireAmount
        comment
        investorName
        investorId
        originatorName
        subTenantId
        status
        loanInfos {
          fortaLoanId
          investorProceeds
        }
        createdBy
        createdDate
        updatedBy
        updatedDate
      }
    }
  `;

  private RETURN_WIRE = gql`
    mutation ReturnWire($returnInput: ReturnInput) {
      returnWire(returnInput: $returnInput) {
        wireId
        referenceNumber
        receivedDate
        wireAmount
        comment
        investorName
        investorId
        originatorName
        subTenantId
        status
        loanInfos {
          fortaLoanId
          investorProceeds
        }
        createdBy
        createdDate
        updatedBy
        updatedDate
      }
    }
  `;

  private REVERSE_RETURN_WIRE = gql`
    mutation ReverseReturnWire($returnInput: ReturnInput) {
      reverseReturnWire(returnInput: $returnInput) {
        wireId
        referenceNumber
        receivedDate
        wireAmount
        comment
        investorName
        investorId
        originatorName
        subTenantId
        status
        loanInfos {
          fortaLoanId
          investorProceeds
        }
        createdBy
        createdDate
        updatedBy
        updatedDate
      }
    }
  `;

  private SETTLEMENT_COUNT = gql`
    query SettlementCount($settlementCount: SettlementCountInput) {
      settlementCount(settlementCount: $settlementCount) {
        settledCount
      }
    }
  `;

  private RETURN_SETTLEMENT_REQUEST = gql`
    mutation ReturnSettlementRequestReason($returnInput: ReturnInput) {
      returnSettlementRequestReason(returnInput: $returnInput) {
        wireId
        referenceNumber
        receivedDate
        wireAmount
        comment
        investorName
        investorId
        originatorName
        subTenantId
        status
        loanInfos {
          fortaLoanId
          investorProceeds
        }
        createdBy
        createdDate
        updatedBy
        updatedDate
        returnWireReason
      }
    }
  `;

  private DASHBOARD_PORTFOLIO = gql`
    query GetSubTenantDashboardFundingPortfolio($subTenantId: String!) {
      getSubTenantDashboardFundingPortfolio(subTenantId: $subTenantId) {
        commitmentAmount
        outstandingWHPrincipalStats {
          amount
          count
        }
        availableBalance
        maxWetLimit
        currentWetOutstandingStats {
          amount
          count
        }
        wetLimitAvailable
        __typename
      }
    }
  `;
  private MANAGEMENT_PORTFOLIO_SUMMARY = gql`
    query GetMgmtExecPortfolioSummary(
      $getMgmtExecPortfolioSummaryRequest: GetMgmtExecPortfolioSummaryRequest!
    ) {
      getMgmtExecPortfolioSummary(
        getMgmtExecPortfolioSummaryRequest: $getMgmtExecPortfolioSummaryRequest
      ) {
        totalCommitment
        numberOfClients
        totalOutstandings
        numberOfLoans
        averageLtv
        averageFICO
        fundedMTD
        numberOfLoansFundedMTD
        averageUtilization
      }
    }
  `;

  private DELETE_DOCUMENT = gql`
    mutation DeleteDocument($deleteDocumentInput: DeleteDocumentInput!) {
      deleteDocument(deleteDocumentInput: $deleteDocumentInput) {
        originId
        origin
        originType
        __typename
      }
    }
  `;

  private GET_LOANS_WITH_CONDITIONS = gql`
    query GetLoansWithConditions(
      $loansWithConditionsRequest: LoansWithConditionsRequest!
    ) {
      getLoansWithConditions(
        loansWithConditionsRequest: $loansWithConditionsRequest
      ) {
        loansWithConditions
      }
    }
  `;

  private COLLATERAL_SUMMARY = gql`
    query GetCollateralStatusDetails(
      $GetCollateralStatusDetails: GetCollateralStatusDetailsRequest!
    ) {
      getCollateralStatusDetails(
        GetCollateralStatusDetails: $GetCollateralStatusDetails
      ) {
        status
        value
      }
    }
  `;

  private FUNDED_TODAY_COUNT = gql`
    query GetFundedTodayCount($fundedTodayRequest: TenantFundingCardRequest!) {
      getFundedTodayCount(fundedTodayRequest: $fundedTodayRequest) {
        count
      }
    }
  `;

  private SHIPPING_SUMMARY = gql`
    query GetShippingStatusDetails(
      $GetShippingStatusDetails: GetShippingStatusDetailsRequest!
    ) {
      getShippingStatusDetails(
        GetShippingStatusDetails: $GetShippingStatusDetails
      ) {
        status
        value
      }
    }
  `;

  private GET_ALL_ROLES_AND_PERMISSIONS = gql`
    query GetAllRolesAndPermission($subTenantId: String) {
      getAllRolesAndPermission(subTenantId: $subTenantId) {
        roles {
          roleId
          roleName
          permissions
          order
        }
        permissions {
          permissionName
          permissionDisplayName
          groupName
          permissionDescription
        }
        isTenantMfaEnabled
      }
    }
  `;

  private GET_USER_STATUS = gql`
    query GetUserStatus($request: GetUserStatusRequest) {
      getUserStatus(request: $request) {
        isBlocked
        isBruteForceBlocked
        is2FaEnabled
        lastLoggedInDateTime
      }
    }
  `;

  private GET_ALL_USERS = gql`
    query GetUsers($subTenantId: String) {
      getUsers(subTenantId: $subTenantId) {
        users {
          userId
          email
          roleIds
          status
          firstName
          lastName
          phone
          isMfaEnable
        }
      }
    }
  `;

  private GET_USER_INFO = gql`
    query GetUserInfo {
      getUserInfo {
        userId
        email
        roleIds
        status
        firstName
        lastName
        phone
        isMfaEnable
        bannerMessage
        isBannerMessageActive
      }
    }
  `;

  private ADD_USER = gql`
    mutation AddUser($createUserRequest: AddUserInput!) {
      addUser(createUserRequest: $createUserRequest) {
        userId
      }
    }
  `;

  private UPDATE_USER = gql`
    mutation UpdateUser($updateUserRequest: UpdateUserInput!) {
      updateUser(updateUserRequest: $updateUserRequest) {
        userId
      }
    }
  `;

  private PERFORM_ACTION_ON_USER = gql`
    mutation PerformActionOnUser($request: PerformActionOnUserRequest) {
      performActionOnUser(request: $request) {
        success
      }
    }
  `;
  private TENANT_INDEX_ACTIVATION = gql`
    mutation TenantIndexActivation(
      $indexActivationInput: TenantIndexActivationInput
    ) {
      tenantIndexActivation(indexActivationInput: $indexActivationInput) {
        indexId
        indexType
        indexSubType
        indexRate
        apiFlag
        createdBy
        createdOn
        updatedBy
        updatedOn
        indexName
        isActive
        subTenantProductCount
      }
    }
  `;

  private DELETE_LOAN_DOCUMENT = gql`
    mutation DeleteLoanDocument($deleteDocumentInput: DeleteDocumentInput!) {
      deleteDocument(deleteDocumentInput: $deleteDocumentInput) {
        origin
        origin
        originType
      }
    }
  `;

  private GET_ALL_ADDITIONAL_WIRES = gql`
    query GetAllAdditionalWires($fortaLoanId: String!) {
      getAllAdditionalWires(fortaLoanId: $fortaLoanId) {
        additionalWireId
        updatedBy
        createdBy
        createdOn
        updatedOn
        wireAmount
        wireStatus
        requestedWireDate
        fedRefNumber
        orderNumber
        loanClosingAgent {
          primaryBankABA
          orderNumber
          closingAgentName
          closingAgentAddress
          closingAgentCity
          closingAgentState
          closingAgentZip
          bankName
          accountNumber
          accountName
          bankNameFCT
          closingAgentBranch
          agentId
          secureInsightEACode
          fctAbaAccountNumber
        }
      }
    }
  `;

  private NOTES_OUTSTANDING_COUNT = gql`
    query GetNotesOutStandingCount(
      $notesOutStandingCountRequest: NotesOutStandingCountRequest
    ) {
      getNotesOutStandingCount(
        notesOutStandingCountRequest: $notesOutStandingCountRequest
      ) {
        notesOutstandingCount
      }
    }
  `;

  private GET_DASHBOARD_PENDING_INVESTOR_COUNT = gql`
    query GetDashboardPendingInvestorCount {
      getDashboardPendingInvestorCount(
        dashboardPendingInvestorCountRequest: { subTenantIds: [] }
      ) {
        count
      }
    }
  `;

  getDashboardPendingInvestorCount(subtenantIds: string[]): any {
    return this.apollo.query({
      query: this.GET_DASHBOARD_PENDING_INVESTOR_COUNT,
      variables: {
        dashboardPendingInvestorCountRequest: {
          subTenantIds: subtenantIds,
        },
      },
    });
  }

  private GET_LOANS_TO_BE_SHIPPED = gql`
    query GetLoansToBeShipped {
      getLoansToBeShipped {
        client
        fortaLoanId
        originatorLoanNumber
        noteAmount
        investorName
        shipRequestDate
        dateShipped
        displayLoanId
        fundedDate
      }
    }
  `;
  private GET_EXPENSES = gql`
    query GetExpenses($fortaLoanId: String!, $transactionType: String!) {
      getLoanLevelInterests(
        fortaLoanId: $fortaLoanId
        transactionType: $transactionType
      ) {
        fortaLoanId
        amount
        transaction
        date
        indexRate
        financingSourceId
        currentWHP
        financingSource {
          source
          rate
          active
          sourceId
        }
      }
    }
  `;

  private GET_COLLATERAL_DASHBOARD_WETLOAN = gql`
    query GetCollateralDashboardWetLoan($subTenantIds: [String]!) {
      getCollateralDashboardWetLoan(
        GetCollateralDashboardWetLoanRequest: { subTenantIds: $subTenantIds }
      ) {
        count
      }
    }
  `;

  private GET_SUB_TENANT_LEGAL_NAME = gql`
    query GetSubtenantLegalName($subTenantId: ID!) {
      subTenant(subTenantId: $subTenantId) {
        legalName
      }
    }
  `;

  private GET_TENANT_NAME = gql`
    query GetTenantNameByTenantId($tenantId: String!) {
      getTenantByTenantId(tenantId: $tenantId) {
        name
      }
    }
  `;

  private LOAN_SEARCH = gql`
    query LoanSearch($LoanSearchRequest: LoanSearchRequest!) {
      loanSearch(LoanSearchRequest: $LoanSearchRequest) {
        searchedLoan {
          subTenantId
          tenantId
          fortaLoanId
          displayLoanId
          productName
          fedRefNumber
          status
          createdBy
          createdOn
          updatedBy
          updatedOn
          borrowers {
            primary
            citizenship
            firstName
            lastName
            middleName
            dob
            ssn
            borrowerType
            totalAnnualIncome
            creditScore
            trustName
            businessName
            einNumber
          }
          collateral {
            acquisitionSource
            address
            city
            cltv
            collateralStatus
            propertyType
            county
            ltv
            occupancy
            salePrice
            state
            unitCount
            wet
            zip
            note {
              amount
              date
              rate
              term
            }
            appraisal {
              appraisalCompany
              appraisedValue
              appraisedDate
              appraisalType
              appraisalName
            }
            trackingNumber
            trackingUrl
            updatedBy
            receivedBy
            updatedDate
            receivedDate
            acceptedDate
            comments
          }
          originator {
            originatorName
            loanNumber
            productCode
          }
          loanDetail {
            amortizationType
            cashOutAmount
            requestedWireDate
            documentationType
            dtiBackend
            dtiFrontend
            eNote
            firstDueDate
            lienPosition
            mersNum
            paymentAmount
            piti
            refinanceType
            transactionType
            loanAmount
            warehousePrinciple
            otherLiens
            wireAmount
            highPriorityIndicator
            mip
            originalPrinBal
            unpaidPrinBal
            lastPaymentDate
            nextPaymentDate
          }
        }
        recentlyViewLoans {
          subTenantId
          tenantId
          fortaLoanId
          displayLoanId
          productName
          fedRefNumber
          status
          createdBy
          createdOn
          updatedBy
          updatedOn
          borrowers {
            primary
            citizenship
            firstName
            lastName
            middleName
            dob
            ssn
            borrowerType
            totalAnnualIncome
            creditScore
            trustName
            businessName
            einNumber
          }
          collateral {
            acquisitionSource
            address
            city
            cltv
            collateralStatus
            propertyType
            county
            ltv
            occupancy
            salePrice
            state
            unitCount
            wet
            zip
            note {
              amount
              date
              rate
              term
            }
            appraisal {
              appraisalCompany
              appraisedValue
              appraisedDate
              appraisalType
              appraisalName
            }
            trackingNumber
            trackingUrl
            updatedBy
            receivedBy
            updatedDate
            receivedDate
            acceptedDate
            comments
          }
          originator {
            originatorName
            loanNumber
            productCode
          }
          loanDetail {
            amortizationType
            cashOutAmount
            requestedWireDate
            documentationType
            dtiBackend
            dtiFrontend
            eNote
            firstDueDate
            lienPosition
            mersNum
            paymentAmount
            piti
            refinanceType
            transactionType
            loanAmount
            warehousePrinciple
            otherLiens
            wireAmount
            highPriorityIndicator
            mip
            originalPrinBal
            unpaidPrinBal
            lastPaymentDate
            nextPaymentDate
          }
        }
      }
    }
  `;

  private GET_LOAN_READY_TO_SHIP_COUNT = gql`
    query GetLoanReadyToShip($loanReadyToShip: LoanReadyToShipRequest) {
      getLoanReadyToShip(loanReadyToShip: $loanReadyToShip) {
        count
      }
    }
  `;

  private LOANS_PENDING_SHIP_REQUEST_COUNT = gql`
    query GetLoanPendingShipDate($loanPendingShip: LoanPendingShipRequest) {
      getLoanPendingShipDate(loanPendingShip: $loanPendingShip) {
        count
      }
    }
  `;

  private ADD_PRODUCT_DETAILS = gql`
    mutation AddOrUpdateProduct($product: ProductRequest!) {
      addOrUpdateProduct(product: $product) {
        productId
        subTenantId
        productName
        productType
        mraDescription
        subLimit
        advanceBasis
        indexBasis
        indexName
        subBasis
        requiredDocs {
          file
        }
        eNote
        autoFund
        monthlyInterestCollection
        status
        productFees {
          dailyWetFee
          wireFee
          fileFee
          eNoteFee
          shippingFee
          drawFee
        }
        interestAccrualMethod
        dwellPeriodsList {
          minDwellPeriod
          maxDwellPeriod
          marginInc
          agingFee
          advanceRate
          commentReq
        }
        rules {
          maxLoanAmount
          minFrontEndDti
          maxFrontEndDti
          maxBackEndDti
          loanTermLimit
          maxLtv
          minCreditScore
          minLoanAmount
          maxCLTV
          noteDate
          ssnRequired
          occupancy
          states
          docTypes
          amortization
          maxLoanAmountActive
          minFrontEndDtiActive
          loanTermLimitActive
          maxLtvActive
          minCreditScoreActive
          minLoanAmountActive
          maxCLTVActive
          noteDateActive
          ssnRequiredActive
          occupancyActive
          statesActive
          docTypesActive
          amortizationActive
          minimumNoteAmount
          maximumNoteAmount
          lienPosition
          propertyType
          maxCreditScore
          maxFrontEndDtiActive
          maxBackEndDtiActive
          minimumNoteAmountActive
          maximumNoteAmountActive
          lienPositionActive
          propertyTypeActive
          maxCreditScoreActive
        }
        investorsList
        productFloorRate
        subLimitBasis
        limit
        indexUpdateFrequency
        indexUpdateFreqValue
        adjuster
        additionalDraws {
          file
        }
        additionalDrawsFlag
      }
    }
  `;
  private GET_LOAN_BY_ID_SHIPPING_TRANSACTION = gql`
    query GetLoanByIdWithShipping(
      $fortaLoanId: String
      $displayLoanId: String
    ) {
      getLoanById(fortaLoanId: $fortaLoanId, displayLoanId: $displayLoanId) {
        subTenantId
        tenantId
        fortaLoanId
        displayLoanId
        productName
        productId
        fedRefNumber
        status
        createdBy
        createdOn
        updatedBy
        updatedOn
        originatorParticipation
        maxWHPrincipal
        currentWHPrincipal
        collateral {
          acquisitionSource
          address
          city
          cltv
          collateralStatus
          propertyType
          county
          ltv
          occupancy
          salePrice
          state
          unitCount
          wet
          zip
          note {
            amount
            date
            rate
            term
          }
          trackingNumber
          trackingUrl
          updatedBy
          receivedBy
          updatedDate
          receivedDate
          acceptedDate
          comments
          propertyCount
        }
        investor {
          investorName
          takeoutPercent
          takeoutExpiration
          investorId
        }
        originator {
          originatorName
          loanNumber
          productCode
        }
        shippingTransaction {
          trackingNumber
          trackingURL
          documents {
            createdBy
            createdOn
            documentId
            documentName
            documentType
            documentURL
            updatedBy
            updatedOn
            size
          }
        }
        isSecureInsightEnable
      }
    }
  `;

  private GET_FEE_SUMMARY = gql`
    query GetFeeSummary($fortaLoanId: String, $displayLoanId: String) {
      getLoanById(fortaLoanId: $fortaLoanId, displayLoanId: $displayLoanId) {
        feeSummary {
          totalFeeAmount
          totalFeeAdjustmentAmount
          totalFeeCollectionAmount
        }
      }
    }
  `;

  private GET_INTEREST_SUMMARY = gql`
    query GetInterestSummary($fortaLoanId: String, $displayLoanId: String) {
      getLoanById(fortaLoanId: $fortaLoanId, displayLoanId: $displayLoanId) {
        interestSummary {
          totalInterestAmount
          totalInterestAdjustmentAmount
          totalInterestCollectionAmount
        }
      }
    }
  `;

  getLoanReadyToShip(subtenantIds: string[]) {
    return this.apollo.query({
      query: this.GET_LOAN_READY_TO_SHIP_COUNT,
      variables: {
        loanReadyToShip: {
          subTenantIds: subtenantIds,
        },
      },
    });
  }

  getTenantName(tenantId: string) {
    return this.apollo.query({
      query: this.GET_TENANT_NAME,
      variables: {
        tenantId: tenantId,
      },
    });
  }

  getSubTenantLegalName(id: string): any {
    return this.apollo.query({
      query: this.GET_SUB_TENANT_LEGAL_NAME,
      variables: {
        subTenantId: id,
      },
    });
  }

  getSubTenantDashboardFundingPortfolio(id: string): any {
    return this.apollo.query({
      query: this.DASHBOARD_PORTFOLIO,
      variables: {
        subTenantId: id,
      },
    });
  }

  getMgmtExecPortfolioSummary(subtenantIds: string[]): any {
    return this.apollo.query({
      query: this.MANAGEMENT_PORTFOLIO_SUMMARY,
      variables: {
        getMgmtExecPortfolioSummaryRequest: {
          subTenantIds: subtenantIds,
        },
      },
    });
  }

  getEnumerations(): any {
    return this.apollo.query({
      query: this.GET_ENUMERATIONS,
    });
  }

  getLoanById(loanSearch: LoanSearch): any {
    return this.apollo.query({
      query: this.GET_LOAN_BY_ID,
      variables: loanSearch,
    });
  }
  getLoanByIdShippingTransaction(loanSearch: LoanSearch): any {
    return this.apollo.query({
      query: this.GET_LOAN_BY_ID_SHIPPING_TRANSACTION,
      variables: loanSearch,
    });
  }

  getLoanSummaryById(request: LoanSearch): any {
    return this.apollo.query({
      query: this.GET_LOAN_SUMMARY_BY_ID,
      variables: {
        request: request,
      },
    });
  }

  addOrUpdateShipping(shippingInputObj: any): Observable<IShippingResponse> {
    return this.apollo
      .mutate({
        mutation: this.ADD_SHIPPING,
        variables: {
          shippingInput: shippingInputObj,
        },
      })
      .pipe(map((x) => x.data as IShippingResponse));
  }

  applyCurtailmentTransaction(
    curtailmentTransactionRequestObj: any
  ): Observable<any> {
    return this.apollo.mutate({
      mutation: this.APPLY_CURTAILMENT_TRANSACTION,
      variables: {
        curtailmentTransactionRequest: curtailmentTransactionRequestObj,
      },
    });
  }

  addOrUpdateShippingOptions(
    shippingOptionsInputObj: any
  ): Observable<IShippingOptionResponse> {
    return this.apollo
      .mutate({
        mutation: this.ADD_SHIPPING_OPTIONS,
        variables: {
          shippingOptionsInput: shippingOptionsInputObj,
        },
      })
      .pipe(map((x) => x.data as IShippingOptionResponse));
  }

  addOrUpdateManualLoanCondition(manualConditionObj: any): any {
    return this.apollo.mutate({
      mutation: this.ADD_FUNDING_CONDITIONS,
      variables: {
        manualCondition: manualConditionObj,
      },
    });
  }

  addLoan(loanObj: any): any {
    return this.apollo.mutate({
      mutation: this.ADD_LOAN,
      variables: {
        loan: loanObj,
      },
    });
  }

  cancelLoan(fortaLoanId: string, subTenantId: string): any {
    return this.apollo.mutate({
      mutation: this.CANCEL_LOAN,
      variables: {
        fortaLoanId: fortaLoanId,
        subTenantId: subTenantId,
      },
    });
  }

  addTenant(tenantObj: any): any {
    return this.apollo.mutate({
      mutation: this.ADD_TENANT,
      variables: {
        tenant: tenantObj,
      },
    });
  }

  updateTenantIndex(tenantIndexInput: UpdateTenantIndexInput): any {
    return this.apollo.mutate({
      mutation: this.UPDATE_TENANT_INDEX,
      variables: {
        updateTenantIndexInput: tenantIndexInput,
      },
    });
  }
  updateIndexRate(updateIndexRateInput: String): any {
    return this.apollo.mutate({
      mutation: this.UPDATE_TENANT_INDEX_RATE,
      variables: {
        updateIndexRateInput: updateIndexRateInput,
      },
    });
  }
  getLoginUserViewedLoanIds(size: number): any {
    return this.apollo.query({
      query: this.GET_RECENTLY_SEARCHED_LOANS,
      variables: {
        size: size,
      },
    });
  }

  getLoanConditionsById(id: string): any {
    return this.apollo.query({
      query: this.GET_LOAN_CONDITIONS,
      variables: {
        fortaLoanId: id,
      },
    });
  }

  updateLoanCondition(
    fortaLoanId: string,
    conditionId: string,
    decision: string,
    explanation: string,
    conditionTrigger: string
  ): any {
    return this.apollo.mutate({
      mutation: this.UPDATE_LOAN_CONDITION,
      variables: {
        fortaLoanId: fortaLoanId,
        conditionId: conditionId,
        decision: decision,
        explanation: explanation,
        conditionTrigger: conditionTrigger,
      },
    });
  }

  createShippingInstruction(ShipingInstruction: ShippingInstructions): any {
    return this.apollo.mutate({
      mutation: this.CREATE_SHIPPING_INSTRUCTIONS,
      variables: {
        ShipingInstruction: ShipingInstruction,
      },
    });
  }

  createTenantIndex(tenantIndexRequestInput: CreateTenantIndexInput): any {
    return this.apollo.mutate({
      mutation: this.CREATE_TENANT_INDEX,
      variables: {
        tenantIndexRequestInput: tenantIndexRequestInput,
      },
    });
  }

  updateShippingInstructions(ShipingInstruction: ShippingInstructions): any {
    return this.apollo.mutate({
      mutation: this.UPDATE_SHIPPING_INSTRUCTIONS,
      variables: {
        ShipingInstruction: ShipingInstruction,
      },
    });
  }

  deleteShippingInstruction(loanId: ShippingInstructions): any {
    return this.apollo.mutate({
      mutation: this.DELETE_SHIPPING_INSTRUCTIONS,
      variables: {
        fortaLoanId: loanId,
      },
    });
  }

  getShippingInstructionsById(id: string): any {
    return this.apollo.query({
      query: this.Get_SHIPPING_INSTRUCTIONS,
      variables: {
        fortaLoanId: id,
      },
    });
  }

  getAllTenantInvestor(): any {
    return this.apollo.query({
      query: this.GET_ALL_TENANT_INVESTOR,
    });
  }

  getTenantInvestor(investorId: string): any {
    return this.apollo.query({
      query: this.GET_TENANT_INVESTOR,
      variables: {
        investorId: investorId,
      },
    });
  }

  updateTenantInvestor(updateTenantInvestorInput: any): any {
    return this.apollo.mutate({
      mutation: this.UPDATE_TENANT_INVESTOR,
      variables: {
        updateTenantInvestorInput: updateTenantInvestorInput,
      },
    });
  }

  addTenantInvestor(tenantInvestor: TenantInvestor): any {
    return this.apollo.mutate({
      mutation: this.ADD_TENANT_INVESTOR,
      variables: {
        tenantInvestorInput: tenantInvestor,
      },
    });
  }

  getSubTenant(id: string): any {
    return this.apollo.query({
      query: this.GET_PRODUCT_SUMMARY,
      variables: {
        subTenantId: id,
      },
    });
  }

  productsBySubTenant(id: string): any {
    return this.apollo.query({
      query: this.PRODUCTS_BY_SUBTENENT,
      variables: {
        subTenantId: id,
      },
    });
  }

  getStateLicenses(subTenantId: string): any {
    return this.apollo.query({
      query: this.GET_STATE_LICENSES,
      variables: {
        subTenantId: subTenantId,
      },
    });
  }

  getAllSubTenants(): any {
    return this.apollo.query({
      query: this.GET_SUB_TENANTS,
    });
  }

  getAllSubTenantsSummary(): any {
    return this.apollo.query({
      query: this.GET_ALL_SUB_TENANTS_SUMMARY,
    });
  }

  getShipping(): Observable<IShippingDataResponse> {
    return this.apollo
      .query<IShippingDataResponse>({
        query: this.GET_SHIPPINGS,
      })
      .pipe(map(({ data }) => data));
  }

  getSubTenantById(id: string): any {
    return this.apollo.query({
      query: this.GET_SUB_TENANT,
      variables: {
        subTenantId: id,
      },
    });
  }

  getOriginatorNameById(id: string): any {
    return this.apollo.query({
      query: this.GET_SUB_TENANT_NAME,
      variables: {
        subTenantId: id,
      },
    });
  }

  getAllActivityLog(request: any): any {
    return this.apollo.query({
      query: this.GET_ALL_ACTIVITY_LOG,
      variables: {
        request: request,
      },
    });
  }

  getAllSubTenantClosingAgents(id: string): any {
    return this.apollo.query({
      query: this.GET_ALL_SUBTENANT_CLOSING_AGENTS,
      variables: {
        subTenantId: id,
      },
    });
  }

  saveSubTenantClosingAgents(input: SubTenantClosingAgentInput): any {
    return this.apollo.mutate({
      mutation: this.SAVE_SUBTENANT_CLOSING_AGENTS,
      variables: {
        subTenantClosingAgentInput: input,
      },
    });
  }

  updateCollateral(fortaLoanId: string, collateral: any) {
    return this.apollo.mutate({
      mutation: this.UPDATE_COLLATERAL,
      variables: {
        fortaLoanId: fortaLoanId,
        collateral: collateral,
      },
    });
  }

  getShippingToReady(): Observable<
    ApolloQueryResult<ShippingPipelineRecordsResponse>
  > {
    return this.apollo.query<ShippingPipelineRecordsResponse>({
      query: this.GET_SHIPPING_PIPELINE_RECORDS,
      variables: {},
    });
  }

  deleteShippingOption(
    shippingOptionId: string,
    vendorName: string
  ): Observable<any> {
    return this.apollo
      .mutate({
        mutation: this.DELETE_SHIPPING,
        variables: {
          shippingOptionId: shippingOptionId,
          vendorName: vendorName,
        },
      })
      .pipe(map((res) => res.data));
  }

  deleteLoanDocument(deleteDocumentInput: DeleteDocument) {
    return this.apollo.mutate({
      mutation: this.DELETE_LOAN_DOCUMENT,
      variables: {
        deleteDocumentInput: deleteDocumentInput,
      },
    });
  }

  getAllSubtenantInvestors(subTenantId: String) {
    return this.apollo.query({
      query: this.GET_ALL_SUB_TENANT_INVESTORS,
      variables: {
        subTenantId: subTenantId,
      },
    });
  }

  getApprovedAndActiveInvestors() {
    return this.apollo.query({
      query: this.GET_APPROVED_AND_ACTIVE_INVESTORS,
    });
  }

  getLoanStatus(fortaLoanId: string) {
    return this.apollo.query({
      query: this.GET_LOAN_STATUS,
      variables: {
        fortaLoanId: fortaLoanId,
      },
    });
  }

  // requestFundingByFortaLoanId(fortaLoanId: string) {
  //   return this.apollo.mutate({
  //     mutation: this.REQUEST_FUNDING_BY_FORTA_LOAN_ID,
  //     variables: {
  //       fortaLoanId: fortaLoanId,
  //     },
  //   });
  // }

  shipCollateralWithIntegrationRequest(
    shipCollateralWithIntegrationRequest: ShipCollateralWithIntegration
  ) {
    return this.apollo.mutate({
      mutation: this.SHIP_COLLATERAL_WITH_INTEGRATION,
      variables: {
        shipCollateralWithIntegrationRequest:
          shipCollateralWithIntegrationRequest,
      },
    });
  }

  shipCollateralWithoutIntegrationRequest(
    selfCustodianFlag: Boolean,
    shipCollateralWithoutIntegrationRequest: shipCollateralWithOutIntegration
  ): any {
    return this.apollo.mutate({
      mutation: this.SHIP_COLLATERAL_WITHOUT_INTEGRATION,
      variables: {
        shipCollateralWithOutIntegrationRequest:
          shipCollateralWithoutIntegrationRequest,
        selfCustodianFlag: selfCustodianFlag,
      },
    });
  }

  updateFedRefNumber(fortaLoanId: string, fedRefNumber: string) {
    return this.apollo.mutate({
      mutation: this.UPDATE_FED_REF_NUMBER,
      variables: {
        fortaLoanId: fortaLoanId,
        fedRefNumber: fedRefNumber,
      },
    });
  }

  validateBatchLoanNumbers(validateBatchLoanNumber: ValidateBatchLoanNumber) {
    return this.apollo.mutate({
      mutation: this.VALIDATE_BACTH_LOAN_NUMBERS,
      variables: {
        validateLoanInput: validateBatchLoanNumber,
      },
    });
  }

  getShippedLoans(settlementLoan: SettlementLoan) {
    return this.apollo.mutate({
      mutation: this.GET_SHIPPED_LOANS,
      variables: {
        addLoanRequest: settlementLoan,
      },
    });
  }

  manualVendorValidation(fortaLoanId: String) {
    return this.apollo.query({
      query: this.MANUAL_VENDOR_VALIDATION,
      variables: {
        fortaLoanId: fortaLoanId,
      },
    });
  }

  getMatchedWires(purchaseAdviceAmount: String) {
    return this.apollo.mutate({
      mutation: this.GET_MATCHING_WIRES_WITH_PURCHASE_ADVICE_AMOUNT,
      variables: {
        purchaseAdviceAmount: purchaseAdviceAmount,
      },
    });
  }

  getAllLoansByStatus(getAllLoansByStatusRequest: any) {
    return this.apollo.query({
      query: this.GET_ALL_LOANS_BY_STATUS,
      variables: {
        getAllLoansByStatusRequest: getAllLoansByStatusRequest,
      },
    });
  }

  getAllSettlementWire(): any {
    return this.apollo.query({
      query: this.GET_ALL_SETTLEMENT_WIRE,
      variables: {},
    });
  }

  checkDuplicateWireInstruction(abaAndAccNoInput: ABAAndAccNoInput) {
    return this.apollo.query({
      query: this.CHECK_DUPLICATE_WIRE_INSTRUCTION,
      variables: {
        abaAndAccNoInput: abaAndAccNoInput,
      },
    });
  }

  getLoanLevelInterests(fortaLoanId: string) {
    return this.apollo.query({
      query: this.GET_LOAN_LEVEL_INTERESTS,
      variables: {
        fortaLoanId: fortaLoanId,
      },
    });
  }

  adjustLoanInterestDetails(adjustLoanInterestInput: AdjustInterest) {
    return this.apollo.mutate({
      mutation: this.ADJUST_INTEREST,
      variables: {
        adjustLoanInterestInput: adjustLoanInterestInput,
      },
    });
  }

  collectInterest(collectLoanInterestInput: CollectInterest) {
    return this.apollo.mutate({
      mutation: this.COLLECT_INTEREST,
      variables: {
        collectLoanInterestInput: collectLoanInterestInput,
      },
    });
  }

  getWire(referenceNumber: string): any {
    return this.apollo.query({
      query: this.GET_WIRE,
      variables: {
        referenceNumber: referenceNumber,
      },
    });
  }

  confirmWire(settlementWireId: string): any {
    return this.apollo.query({
      query: this.CONFIRM_WIRE,
      variables: {
        settlementWireId: settlementWireId,
      },
    });
  }

  getAllManualConditions(): any {
    return this.apollo.query({
      query: this.GET_ALL_MANUAL_CONDITION_LIST,
    });
  }

  addOrUpdateNewManualCondition(ManualConditionInput: any): any {
    return this.apollo.mutate({
      mutation: this.ADD_CONDITION,
      variables: {
        condition: ManualConditionInput,
      },
    });
  }

  bulkLoanRequestCancel(bulkLoanRequestCancelInputs: any): any {
    return this.apollo.mutate({
      mutation: this.BULK_LOAN_REQUEST_CANCEL,
      variables: {
        bulkLoanRequestCancelInputs: bulkLoanRequestCancelInputs,
      },
    });
  }

  getAllSubTenantApprovedInvestors(subTenantId: string, productName?: string) {
    return this.apollo.query({
      query: this.GET_ALL_SUBTENANT_APPROVED_TENANT_INVESTOR,
      variables: {
        subTenantId: subTenantId,
        productName: productName,
      },
    });
  }

  updateCollateralStatus(BatchCollateralInput: any): any {
    return this.apollo.mutate({
      mutation: this.UPDATE_COLLATERAL_STATUS,
      variables: {
        BatchCollateralInput: BatchCollateralInput,
      },
    });
  }

  getWireLoans(wireId: string): any {
    return this.apollo.query({
      query: this.GET_WIRE_LOANS,
      variables: {
        wireId: wireId,
      },
    });
  }
  getLoanLevelFees(fortaLoanId: string) {
    return this.apollo.query({
      query: this.GET_LOAN_LEVEL_FEES,
      variables: {
        fortaLoanId: fortaLoanId,
      },
    });
  }
  adjustFee(adjustLoanFeeInput: AdjustFee) {
    return this.apollo.mutate({
      mutation: this.ADJUST_FEE,
      variables: {
        adjustLoanFeeInput: adjustLoanFeeInput,
      },
    });
  }

  collectFee(collectLoanFeeInput: CollectFee) {
    return this.apollo.mutate({
      mutation: this.COLLECT_FEE,
      variables: {
        collectLoanFeeInput: collectLoanFeeInput,
      },
    });
  }

  getAllTenants() {
    return this.apollo.query({
      query: this.GET_ALL_TENANTS,
      variables: {},
    });
  }

  getTenantByTenantId(tenantId: string) {
    return this.apollo.query({
      query: this.GET_TENANT_BY_TENANT_ID,
      variables: {
        tenantId: tenantId,
      },
    });
  }
  getTenantById(): any {
    return this.apollo.query({
      query: this.GET_TENANT_BY_ID,
    });
  }

  checkSettlement(checkSettlementInput: CheckSettlement): any {
    return this.apollo.query({
      query: this.CHECK_SETTLEMENT,
      variables: {
        checkSettlementInput: checkSettlementInput,
      },
    });
  }

  returnWire(returnInput: ReturnInput): any {
    return this.apollo.query({
      query: this.RETURN_WIRE,
      variables: {
        returnInput: returnInput,
      },
    });
  }

  reverseReturnWire(returnInput: ReturnInput): any {
    return this.apollo.query({
      query: this.REVERSE_RETURN_WIRE,
      variables: {
        returnInput: returnInput,
      },
    });
  }

  settlementCount(settlementCount: SettlementCountInput): any {
    return this.apollo.query({
      query: this.SETTLEMENT_COUNT,
      variables: {
        settlementCount: settlementCount,
      },
    });
  }

  returnSettlementRequest(returnInput: ReturnInput): any {
    return this.apollo.query({
      query: this.RETURN_SETTLEMENT_REQUEST,
      variables: {
        returnInput: returnInput,
      },
    });
  }

  deleteDocument(deleteConditionDocument: DeleteDocument): any {
    return this.apollo.query({
      query: this.DELETE_DOCUMENT,
      variables: {
        deleteDocumentInput: deleteConditionDocument,
      },
    });
  }

  getCollateralStatusDetails(subtenantIds: string[]): any {
    return this.apollo.query({
      query: this.COLLATERAL_SUMMARY,
      variables: {
        GetCollateralStatusDetails: {
          subTenantIds: subtenantIds,
        },
      },
    });
  }

  getFundedTodayCount(subtenantIds: string[]): any {
    return this.apollo.query({
      query: this.FUNDED_TODAY_COUNT,
      variables: {
        fundedTodayRequest: { subTenantIds: subtenantIds },
      },
    });
  }

  getShippingStatusDetails(subtenantIds: string[]): any {
    return this.apollo.query({
      query: this.SHIPPING_SUMMARY,
      variables: {
        GetShippingStatusDetails: {
          subTenantIds: subtenantIds,
        },
      },
    });
  }

  getAllRolesAndPermission(subTenantId: String): any {
    return this.apollo.query({
      query: this.GET_ALL_ROLES_AND_PERMISSIONS,
      variables: {
        subTenantId: subTenantId,
      },
    });
  }

  getUserStatus(request: GetUserStatusRequest): any {
    return this.apollo.query({
      query: this.GET_USER_STATUS,
      variables: {
        request: request,
      },
    });
  }

  getUsers(subTenantId: String): any {
    return this.apollo.query({
      query: this.GET_ALL_USERS,
      variables: {
        subTenantId: subTenantId,
      },
    });
  }

  getUserInfo(): any {
    return this.apollo.query({
      query: this.GET_USER_INFO,
    });
  }

  addUser(createUserRequest: any): any {
    return this.apollo.mutate({
      mutation: this.ADD_USER,
      variables: {
        createUserRequest: createUserRequest,
      },
    });
  }

  updateUser(
    updateUserRequest: UpdateUserInput
  ): Observable<MutationResult<UpdateUserResponse>> {
    return this.apollo.mutate<UpdateUserResponse, any>({
      mutation: this.UPDATE_USER,
      variables: {
        updateUserRequest: updateUserRequest,
      },
    });
  }
  performActionOnUser(request: any): any {
    return this.apollo.mutate({
      mutation: this.PERFORM_ACTION_ON_USER,
      variables: {
        request: request,
      },
    });
  }
  tenantIndexActivation(indexActivationInput: any): any {
    return this.apollo.mutate({
      mutation: this.TENANT_INDEX_ACTIVATION,
      variables: {
        indexActivationInput: indexActivationInput,
      },
    });
  }

  getAllAdditionalWires(id: string): any {
    return this.apollo.query({
      query: this.GET_ALL_ADDITIONAL_WIRES,
      variables: {
        fortaLoanId: id,
      },
    });
  }

  getNotesOutStandingCount(notesOutStandingCountRequest: any) {
    return this.apollo.query({
      query: this.NOTES_OUTSTANDING_COUNT,
      variables: {
        notesOutStandingCountRequest: notesOutStandingCountRequest,
      },
    });
  }

  loanSearch(request: any): any {
    return this.apollo.query({
      query: this.LOAN_SEARCH,
      variables: {
        LoanSearchRequest: request,
      },
    });
  }

  addOrUpdateAdditionalWire(additionalWireRequest: AdditionalWire): any {
    return this.apollo.mutate({
      mutation: this.SAVE_ADDITIONAL_WIRE,
      variables: {
        additionalWireRequest: additionalWireRequest,
      },
    });
  }

  updateWiresFedReferenceNumber(request: any): any {
    return this.apollo.mutate({
      mutation: this.UPDATE_ADDITIONAL_WIRE_FED_REF_NUMBER,
      variables: {
        updateWiresFedReferenceNumberInput: request,
      },
    });
  }

  changeAdditionalWiresStatus(
    additionalWiresStatusRequest: AdditionalWireStatus
  ): any {
    return this.apollo.mutate({
      mutation: this.CHANGE_ADDITIONAL_WIRE_STATUS,
      variables: {
        additionalWiresStatusRequest: additionalWiresStatusRequest,
      },
    });
  }

  getLoansToBeShipped() {
    return this.apollo.query({
      query: this.GET_LOANS_TO_BE_SHIPPED,
    });
  }
  getTenantIndexesResponse() {
    return this.apollo.query({
      query: this.GET_TENANT_INDEX_VALUES_RESPONSE,
    });
  }

  getCollateralDashboardWetLoanCount(subtenantIds: string[]): any {
    return this.apollo.query({
      query: this.GET_COLLATERAL_DASHBOARD_WETLOAN,
      variables: {
        subTenantIds: subtenantIds,
      },
    });
  }

  getCollateralDashboardWetLoanCountByDays(
    subtenantIds: string[],
    days: any
  ): any {
    return this.apollo.query({
      query: this.GET_COLLATERAL_DASHBOARD_WET_LOAN_BY_DAYS,
      variables: {
        subTenantIds: subtenantIds,
        days: days,
      },
    });
  }

  getLoanPendingShipDate(subtenantIds: any) {
    return this.apollo.query({
      query: this.LOANS_PENDING_SHIP_REQUEST_COUNT,
      variables: {
        loanPendingShip: {
          subTenantIds: subtenantIds,
        },
      },
    });
  }

  getLoansWithConditions(subtenantIds: Array<string>): any {
    return this.apollo.query({
      query: this.GET_LOANS_WITH_CONDITIONS,
      variables: {
        loansWithConditionsRequest: {
          subTenantIds: subtenantIds,
        },
      },
    });
  }

  getPrimaryContact(id: string): any {
    return this.apollo.query({
      query: this.GET_PRIMARY_CONTACT,
      variables: {
        subTenantId: id,
      },
    });
  }
  getExpenses(fortaLoanId: string, transactionType: string) {
    return this.apollo.query({
      query: this.GET_EXPENSES,
      variables: {
        fortaLoanId: fortaLoanId,
        transactionType: transactionType,
      },
    });
  }

  addProductDetails(product: ProductRequest): any {
    return this.apollo.mutate({
      mutation: this.ADD_PRODUCT_DETAILS,
      variables: {
        product: product,
      },
    });
  }

  getLoanInterestSummary(loanSearch: LoanSearch): any {
    return this.apollo.query({
      query: this.GET_INTEREST_SUMMARY,
      variables: loanSearch,
    });
  }

  getLoanFeeSummary(loanSearch: LoanSearch): any {
    return this.apollo.query({
      query: this.GET_FEE_SUMMARY,
      variables: loanSearch,
    });
  }

  constructor(private apollo: Apollo) {}
}
