export const SETTLEMENT_REFERENCE_TYPES = {
  UNIDENTIFIED: 'Unidentified',
  OPERATIONAL_ACCOUNT: 'Operational Account',
};

export const SETTELEMENT_DOCUMENT_TYPES = {
  WIRE_CONFIRMATION_DOCUMENT: 'wireConfirmationDocument',
  PURCHASE_ADVICE_DOCUMENT: 'purchaseAdviceDocument',
};

export const SETTELEMENT_STATUS = {
  NEW: 'New',
  PENDING: 'Pending',
  RETURNED: 'Returned',
  DISPUTED: 'Disputed',
};

export const SETTELEMENT_SOURCE = {
  WH: 'WH',
  IMB: 'IMB',
};

export const USER_TYPE = {
  SUB_TENANT: 'SUB_TENANT',
};
