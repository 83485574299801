import config from '../../auth_config_uat.json';
const {
  domain,
  clientId,
  authorizationParams: { audience },
  apiUri,
  errorPath,
} = config as {
  domain: string;
  clientId: string;
  authorizationParams: {
    audience?: string;
  };
  apiUri: string;
  errorPath: string;
};
export const environment = {
  production: true,
  apiUrl: 'https://api.uat.fortasolutions.com',
  gqlUri: 'https://api.uat.fortasolutions.com/graphql',
  auth: {
    domain,
    clientId,
    authorizationParams: {
      ...(audience && audience !== 'YOUR_API_IDENTIFIER' ? { audience } : null),
      redirect_uri: 'https://uat.fortasolutions.com/secure',
    },
    errorPath,
  },
  httpInterceptor: {
    allowedList: [`${apiUri}/*`],
  },
  contactUsMail: 'info@fortasolutions.com',
};
